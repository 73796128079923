/* rescue模块接口列表 */
import base from "./base";
// 导入http中创建的axios实例
import axios from "@/utils/http";
const rescue = {
  rescueHandbills(params) {
    // 网格救援页面，获取救援电梯信息
    return axios.get(base.dl + "rescue/handbills", {
      params: params,
    });
  },
  cueDiscovery(params) {
    // 网格救援页面，详情页查询
    return axios.get(base.dl + "rescue/discovery", {
      params: params,
    });
  },
  cueLocation(id, params) {
    // 网格救援，获取救援人员位置
    return axios.get(base.dl + "rescue/location/" + id, {
      params: params,
    });
  },
  otherMan(params) {
    // 网格救援，获取选择人员弹框中其他人员数据
    return axios.get(base.dl + "rescue/auto/man", {
      params: params,
    });
  },
  cuePerson(id, eleId) {
    // 网格救援，验证其他人员是否有权限进行维修
    return axios.post(base.dl + "rescue/person/" + id + "?elevatorId=" + eleId);
  },
  cueCloud(id, params) {
    // 网格救援页面，云推送
    return axios.post(
      base.dl +
        "rescue/" +
        id +
        "?belowLat=" +
        params.belowLat +
        "&leftLng=" +
        params.leftLng +
        "&message=" +
        params.message +
        "&rightLng=" +
        params.rightLng +
        "&upwardLat=" +
        params.upwardLat
    );
  },
  recordsPage(params) {
    // 报修记录
    return axios.get(base.dl + "phoneRecord/pages", {
      params: params,
    });
  },
  addrecords(params) {
    // 新增报修记录
    return axios.post(base.dl + "phoneRecord/new", params);
  },
  errorsPage(params) {
    // 故障确认
    return axios.get(base.dl + "elevatorFault/pages", { params: params });
  },
  getIpcWarningList(params) {
    // 故障确认
    return axios.get(base.bl + "ipcWarning/list", { params: params });
  },
  errorsDelete(id) {
    // 故障确认，忽略操作
    return axios.delete(base.dl + "elevatorFault/" + id);
  },
  getOperationRecord(params) {
    // 弹窗操作记录
    return axios.get(
      base.sw + "test/message/fault/messageType/" + params.messageType,
      {
        params: params,
      }
    );
  },
  getCtrlTypes() {
    // 查询所有控制器
    return axios.get(base.sw + "test/message/fault/ctrlTypes");
  },
  getFaultByType(ctrlType) {
    // 根据控制器查询故障代码
    return axios.get(base.sw + "test/message/fault/ctrlType/" + ctrlType);
  },
  setMulFaultCode(params) {
    // 批量设置故障代码
    return axios.put(base.sw + "test/message/fault", params);
  },
};

export default rescue;
