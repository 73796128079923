/**
 * common模块接口列表
 */
import base from "./base";
// 导入http中创建的axios实例
import axios from "@/utils/http";

const detection = {
  partManagePage(params) {
    // 部件管理列表
    return axios.get(base.acc + "mountings/page", { params: params });
  },
  addPartManage(params) {
    // 新增部件
    return axios.post(base.acc + "mountings/one", params);
  },
  editPartManage(id, params) {
    // 编辑部件
    return axios.put(base.acc + "mountings/one/" + id, params);
  },
  delPartManage(id) {
    // 删除部件
    return axios.delete(base.acc + "mountings/one/" + id);
  },
  getPartByElevator(elevatorId) {
    // 根据电梯ID获取电梯部件信息
    return axios.get(base.acc + "mountingsElevator/all/" + elevatorId);
  },
  getNotPartByElevator(elevatorId, params) {
    // 根据电梯ID获取未添加电梯部件信息
    return axios.get(base.acc + "mountings/all/" + elevatorId, {
      params: params,
    });
  },
  addPartByElevator(elevatorId, params) {
    // 新增电梯部件信息
    return axios.post(base.acc + "mountingsElevator/one/" + elevatorId, params);
  },
  editPartByElevator(id, params) {
    // 编辑电梯部件信息
    return axios.put(base.acc + "mountingsElevator/one/" + id, params);
  },
  delPartByElevator(params) {
    // 删除电梯部件信息
    return axios.delete(base.acc + "mountingsElevator/one", { data: params });
  },
  getHistoryByPartId(id) {
    // 根据部件ID获取部件更新信息
    return axios.get(base.acc + "mountingsRenewalHistory/history/" + id);
  },
  getQueryPartPage(params) {
    // 获取问题部件列表
    return axios.get(base.acc + "mountingsElevator/page", { params: params });
  },
  mulAddParts(params) {
    // 批量添加部件
    return axios.post(base.acc + "mountingsElevator/one/bath", params);
  },
  getElevatorByPart() {
    // 获取已配置的电梯
    return axios.get(base.acc + "mountingsElevator/elevator");
  },
  mulEditPartElevator(params) {
    // 批量更新电梯部件信息
    return axios.put(base.acc + "mountingsElevator/one/bath", params);
  },
};

export default detection;
