export default [
  {
    path: "/upkeepContract/upkeepBoard",
    name: "UpkeepBoard",
    component: () => import("../views/upkeepContract/UpkeepBoard.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/upkeepContract/upkeepContractViewupdate",
    name: "UpkeepContractViewupdate",
    component: () =>
      import("../views/upkeepContract/UpkeepContractViewupdate.vue"),
  },
  {
    path: "/upkeepContract/upkeepContractViewcou",
    name: "UpkeepContractViewcou",
    component: () =>
      import("../views/upkeepContract/UpkeepContractViewcou.vue"),
  },
  {
    path: "/upkeepContract/renewUpkeep",
    name: "RenewUpkeep",
    component: () => import("../views/upkeepContract/RenewUpkeep.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/upkeepContract/renewUpkeepAdd",
    name: "RenewUpkeepAdd",
    component: () => import("../views/upkeepContract/RenewUpkeepAdd.vue"),
  },
  {
    path: "/upkeepContract/loseUpkeep",
    name: "LoseUpkeep",
    component: () => import("../views/upkeepContract/LoseUpkeep.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/upkeepContract/loseUpkeepAdd",
    name: "LoseUpkeepAdd",
    component: () => import("../views/upkeepContract/LoseUpkeepAdd.vue"),
  },
  {
    path: "/upkeepContract/billingBoard",
    name: "BillingBoard",
    component: () => import("../views/upkeepContract/BillingBoard.vue"),
  },
  {
    path: "/upkeepContract/collectingBoard",
    name: "CollectingBoard",
    component: () => import("../views/upkeepContract/CollectingBoard.vue"),
  },
  {
    path: "/upkeepContract/upkeepContractView",
    name: "UpkeepContractView",
    component: () => import("../views/upkeepContract/UpkeepContractView.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/upkeepContract/planBathAdd",
    name: "PlanBathAdd",
    component: () => import("../views/upkeepContract/PlanBathAdd.vue"),
  },
  {
    path: "/upkeepContract/upkeepContractViewschedule",
    name: "UpkeepContractViewschedule",
    component: () =>
      import("../views/upkeepContract/UpkeepContractViewschedule.vue"),
  },
  {
    path: "/upkeepContract/upkeepContractViewadd",
    name: "UpkeepContractViewadd",
    component: () =>
      import("../views/upkeepContract/UpkeepContractViewadd.vue"),
  },
  {
    path: "/upkeepContract/dataIn",
    name: "DataIn",
    component: () => import("../views/upkeepContract/DataIn.vue"),
  },
];
