export default [
  {
    path: "/supervision/blacklist",
    name: "Blacklist",
    component: () => import("../views/supervision/Blacklist.vue"),
  },
  {
    path: "/supervision/exposure",
    name: "Exposure",
    component: () => import("../views/supervision/Exposure.vue"),
  },
];
