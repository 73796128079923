/* advert模块接口列表 */
import base from "./base";
// 导入http中创建的axios实例
import axios from "@/utils/http";
const advert = {
  deviceList(params) {
    // 终端列表
    return axios.get(base.ad + "device/list", {
      params: params,
    });
  },
  addDevice(params) {
    // 新增终端
    return axios.post(base.ad + "device/save", params);
  },
  deviceDetail(params) {
    // 终端详情
    return axios.get(base.ad + "device/detail", {
      params: params,
    });
  },
  updateDevice(params) {
    // 修改终端
    return axios.post(base.ad + "device/update", params);
  },
  deleteDevice(ids) {
    // 删除终端
    return axios.post(base.ad + "device/remove?ids=" + ids);
  },
  controls(m, ids) {
    // 按钮组 m 方法
    return axios.post(base.ad + "device/" + m + "?ids=" + ids);
  },
  getProgramByDevice(id) {
    // 通过设备ID查询节目
    return axios.get(base.ad + "/program/selectByDevice/" + id);
  },
  changeVolume(ids, volume) {
    // 调节音量
    return axios.post(
      base.ad + "device/volume" + "?ids=" + ids + "&volume=" + volume
    );
  },
  matterList(params) {
    // 素材列表
    return axios.get(base.ad + "material/list", {
      params: params,
    });
  },
  auditMatter(ids, status) {
    return axios.post(
      base.ad + "material/audit?ids=" + ids + "&status=" + status
    );
  },
  deleteMatter(ids) {
    // 删除素材
    return axios.post(base.ad + "material/remove?ids=" + ids);
  },
  programList(params) {
    // 节目列表
    return axios.get(base.ad + "program/list", {
      params: params,
    });
  },
  getDeviceByProgram(programId) {
    // 根据节目ID查询设备
    return axios.get(base.ad + "device/selectByProgram/" + programId);
  },
  addProgram(params) {
    return axios.post(base.ad + "program/save", params);
  },
  editProgram(params) {
    return axios.post(base.ad + "program/update", params);
  },
  deleteProgram(ids) {
    // 删除素材
    return axios.post(base.ad + "program/remove?ids=" + ids);
  },
  auditProgram(ids, status) {
    return axios.post(
      base.ad + "program/audit?ids=" + ids + "&status=" + status
    );
  },
  publishProgram(ids, programId) {
    return axios.post(
      base.ad + "device/deploy?ids=" + ids + "&programId=" + programId
    );
  },
  programDetail(id) {
    // 节目详情
    return axios.get(base.ad + "program/detail", { params: { id: id } });
  },
};

export default advert;
