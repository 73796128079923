<template>
  <footer v-if="show">{{ $t("i18n.copyright") }}</footer>
</template>
<script>
import { reactive, toRefs } from "vue";
export default {
  name: "foot",
  setup() {
    const state = reactive({
      // 正式环境展示，其他环境不显示
      show: location.hostname === "iot.joylive.com" ? true : false,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss">
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 28px;
  line-height: 28px;
  background: url(../assets/dibu.png) no-repeat center center;
  background-size: cover;
  text-align: center;
  color: $white;
  width: 100%;
  font-size: $font;
  z-index: 9999;
}
</style>
