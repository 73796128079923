/* supervision模块接口列表 */
import base from "./base";
import axios from "@/utils/http"; // 导入http中创建的axios实例
const supervision = {
  blackPages(params) {
    // 获取黑名单
    return axios.get(base.dl + "blacklist/pages", {
      params: params,
    });
  },
  addBlack(params) {
    // 新增黑名单
    return axios.post(base.dl + "blacklist/new", params);
  },
  editBlack(id, params) {
    // 编辑黑名单
    return axios.put(base.dl + "blacklist/" + id, params);
  },
  delBlack(id) {
    // 删除黑名单
    return axios.delete(base.dl + "blacklist/" + id);
  },
  exposurePages(params) {
    // 获取曝光台
    return axios.get(base.dl + "exposureList/pages", {
      params: params,
    });
  },
  addExposure(params) {
    // 新增曝光台
    return axios.post(base.dl + "exposureList/new", params);
  },
  editExposure(id, params) {
    // 编辑曝光台
    return axios.put(base.dl + "exposureList/" + id, params);
  },
  delExposure(id) {
    // 删除曝光台
    return axios.delete(base.dl + "exposureList/" + id);
  },
  detectionPage(params) {
    // 动态监测列表
    return axios.get(base.dl + "elevator/detection/pages", {
      params: params,
    });
  },
  detectionOne(id) {
    // 动态监测查看
    return axios.get(base.dl + "elevator/detection/" + id);
  },
};

export default supervision;
