import router from "./router";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import ElementPlus from "element-plus";
import enLocale from "element-plus/lib/locale/lang/en";
import zhLocale from "element-plus/lib/locale/lang/zh-cn";
import "element-plus/lib/theme-chalk/index.css";
// import "element-plus/dist/index.css";
import App from "./App.vue";
import api from "./utils/api";
import config from "./utils/config";
import defined from "../public/js/define.js";
import jessibuca from "../public/js/video/jessibuca.js"
import decoder from "../public/js/video/decoder.js"
import store from "./store";
// import BaiduMap from 'vue-baidu-map-3x'

const messages = {
  en: {
    el: enLocale.el,
    i18n: require("./common/lang/en"),
  },
  zh: {
    el: zhLocale.el,
    i18n: require("./common/lang/zh"),
  },
};
const i18n = createI18n({
  locale: localStorage.getItem("PLAY_LANG") || "zh",
  fallbackLocale: "en",
  messages,
  silentTranslationWarn: true,
});

const app = createApp(App);
// 按钮权限管理
app.directive("has", {
  mounted(el, binding) {
    let permissions = defined.QUERY.concat(defined.OTHER);
    let hasBtn = permissions.includes(binding.value);
    //没有权限则删除父元素，或者隐藏当前元素
    if (!hasBtn) {
      if (!el.parentNode) {
        el.style.display = "none";
      } else {
        el.parentNode.removeChild(el);
      }
    }
  },
});

app.directive("preventReClick", {
  // 防止重复点击
  mounted(el, binding) {
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  },
});

app.directive("title", {
  beforeMount: function (el) {
    document.title = el.dataset.title;
  },
});
app.config.globalProperties.$api = api;
app.config.globalProperties.$defined = defined;
app.config.globalProperties.$config = config;
app.config.globalProperties.$point = ["108.757101", "37.620469"];
app
  .use(store)
  .use(router)
  .use(ElementPlus, { i18n: i18n.global.t })
  .use(i18n)
  // .use(BaiduMap,{ak:"lkpFC6vaOa89VWOpUDHRqOExmGZ1mmz4"})
  .mount("#app");
