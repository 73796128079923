export default [
  {
    path: "/install/opertionNode",
    name: "OpertionNode",
    component: () => import("../views/install/OpertionNode.vue"),
  },
  {
    path: "/install/opertionNodeConfig",
    name: "OpertionNodeConfig",
    component: () => import("../views/install/OpertionNodeConfig.vue"),
  },
  {
    path: "/install/config",
    name: "Config",
    component: () => import("../views/install/Config.vue"),
  },
  {
    path: "/install/contract",
    name: "Contract",
    component: () => import("../views/install/Contract.vue"),
  },
  {
    path: "/install/contractAdd",
    name: "ContractAdd",
    component: () => import("../views/install/ContractAdd.vue"),
  },
  {
    path: "/install/contractDetail",
    name: "ContractDetail",
    component: () => import("../views/install/ContractDetail.vue"),
  },
  {
    path: "/install/workOrder",
    name: "WorkOrder",
    component: () => import("../views/install/WorkOrder.vue"),
  },
  {
    path: "/install/orderDetail",
    name: "OrderDetail",
    component: () => import("../views/install/OrderDetail.vue"),
  },
];
