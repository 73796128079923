export default [
  {
    path: "/gridRescue/gridRescue",
    name: "GridRescue",
    component: () => import("../views/gridRescue/GridRescue.vue"),
  },
  {
    path: "/gridRescue/phoneRecord",
    name: "PhoneRecord",
    component: () => import("../views/gridRescue/PhoneRecord.vue"),
  },
  {
    path: "/gridRescue/eFaultConfirm",
    name: "EFaultConfirm",
    component: () => import("../views/gridRescue/EFaultConfirm.vue"),
  },
  {
    path: "/gridRescue/operationRecord",
    name: "OperationRecord",
    component: () => import("../views/gridRescue/OperationRecord.vue"),
  },
  {
    path: "/gridRescue/alarmMessage",
    name: "AlarmMessage",
    component: () => import("../views/gridRescue/AlarmMessage.vue"),
  },
];
