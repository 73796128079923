/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/light");

var $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.setOptions({
  java_package: "com.joylive.iot.ws.domain",
  java_outer_classname: "MessageDto"
})
.addJSON({
  Notify: {
    fields: {
      content: {
        type: "string",
        id: 1
      },
      type: {
        type: "string",
        id: 2
      },
      target: {
        type: "string",
        id: 3
      },
      targetType: {
        type: "string",
        id: 4
      },
      action: {
        type: "string",
        id: 5
      },
      sender: {
        type: "string",
        id: 6
      },
      url: {
        type: "string",
        id: 7
      },
      elevatorNumber: {
        type: "string",
        id: 8
      },
      elevatorShortNumber: {
        type: "string",
        id: 9
      },
      faultTime: {
        type: "string",
        id: 10
      },
      id: {
        type: "string",
        id: 11
      }
    }
  }
});

module.exports = $root;
