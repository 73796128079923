export default [
  {
    path: "/user/authorization",
    name: "Authorization",
    component: () => import("../views/user/Authorization.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/user/userInfo",
    name: "UserInfo",
    component: () => import("../views/user/UserInfo.vue"),
  },
  {
    path: "/user/changePassword",
    name: "ChangePassword",
    component: () => import("../views/user/ChangePassword.vue"),
  },
  {
    path: "/user/addEditAuthorization",
    name: "AddEditAuthorization",
    component: () => import("../views/user/AddEditAuthorization.vue"),
  },
  {
    path: "/user/rolePermissionView",
    name: "RolePermissionView",
    component: () => import("../views/user/RolePermissionView.vue"),
  },
  {
    path: "/user/updateLog",
    name: "UpdateLog",
    component: () => import("../views/user/UpdateLog.vue"),
  },
];
