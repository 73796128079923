/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";
import router from "../router";
import defined from "../../public/js/define.js";
import store from "./store/index.js";

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: "/login",
    query: {
      flag: 0,
      redirect: router.currentRoute.path,
    },
  });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    case 401:
      // defined.tip("请登录后使用", "error");
      toLogin();
      break;
    case 403:
      defined.tip("您无权限使用该功能", "error");
      break;
    case 404:
      defined.tip("请求的资源不存在", "error");
      break;
    default:
      defined.tip(other, "waring");
  }
};

// var CancelToken = axios.CancelToken;
// // 配置取消数组
// let pending = [];
// let removePending = (ever) => {
//   for (let p in pending) {
//     if (pending[p].u === ever.url + "&" + ever.method) {
//       //当当前请求在数组中存在时执行函数体
//       pending[p].f(); //执行取消操作
//       pending.splice(p, 1); //把这条记录从数组中移除
//     }
//   }
// };

// 创建axios实例
var instance = axios.create({
  timeout: 1000 * 3 * 24,
});
instance.defaults.baseURL = "/api";
// 设置post请求头
instance.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    if (config.url != "https://www.tianqiapi.com/api/") {
      var token = store.state.token;
      // var token = localStorage.getItem("token") || store.state.token;
      token && (config.headers.Authorization = token);
    }
    // removePending(config); //在一个ajax发送前执行一下取消操作
    // if (urls.includes(config.url)) {
    // config.cancelToken = new CancelToken((c) => {
    //   // executor 函数接收一个 cancel 函数作为参数
    //   // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
    //   pending.push({ u: config.url + "&" + config.method, f: c });
    // });
    // }
    return config;
  },
  (error) => Promise.error(error)
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功  res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res)
  (res) => {
    // removePending(res.config);
    if (res.status === 200) {
      if (
        res.headers["content-disposition"] ||
        (res.config.url.indexOf("http") > -1 &&
          res.config.url.indexOf("joylive") === -1)
      ) {
        return Promise.resolve(res);
      } else {
        if (res.data.success) {
          return Promise.resolve(res.data);
        } else {
          defined.tip(res.data.description, "warning");
          return Promise.reject(res);
        }
      }
    } else {
      return Promise.reject(res);
    }
  },
  // 请求失败
  (error) => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      // store.commit("changeNetwork", false);
      // defined.tip("没网了，请重新联网刷新重试！", "error");
    }
  }
);

export default instance;
