<template>
  <el-affix>
    <header
      :class="{ en: $i18n.locale == 'en', phoneClass: screenWidth < 1432 }"
    >
      <div class="maxContent" v-if="user">
        <div
          v-if="screenWidth > 1180 || screenWidth <= 1024"
          class="div-logo"
          @click="enterIndex"
        >
          <img
            v-if="user.comLogo"
            :src="$defined.imgUrl + user.comLogo"
            class="img-logo"
          />
          <img
            v-else-if="user"
            src="../assets/indexImg/logo1.png"
            class="img-logo"
          />
          <img v-else class="img-logo" style="opacity: 0" />
        </div>
        <div class="lines" v-if="screenWidth < 1432" @click="showMenu">
          <span></span><span></span><span></span>
        </div>
        <el-menu
          :class="isShowMenu ? 'active' : ''"
          :default-active="activeIndex"
          :unique-opened="true"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-submenu
            v-for="item in menus"
            :key="item"
            :index="item.data"
            :route="item.href"
          >
            <template #title>{{ $t("i18n." + item.locale) }}</template>
            <template v-for="(child, index) in item.children" :key="index">
              <el-menu-item
                :key="child.data"
                v-if="child.children.length == 0"
                :index="child.data"
                :route="child.href"
                @click="handleSelect(child)"
              >
                {{ $t("i18n." + child.locale) }}
              </el-menu-item>
              <el-submenu
                :key="child.data"
                v-if="child.children.length > 0"
                :index="child.data"
                :route="child.href"
              >
                <template #title>{{ $t("i18n." + child.locale) }}</template>
                <template v-for="grand in child.children" :key="grand">
                  <el-menu-item
                    v-if="grand.children.length == 0"
                    :index="grand.data"
                    :route="grand.href"
                    @click="handleSelect(grand)"
                  >
                    {{ $t("i18n." + grand.locale) }}
                  </el-menu-item>
                  <el-submenu
                    v-if="grand.children.length > 0"
                    :index="grand.data"
                    :route="grand.href"
                  >
                    <template #title>
                      {{ $t("i18n." + grand.locale) }}
                    </template>
                    <el-menu-item
                      v-for="descendant in grand.children"
                      :key="descendant"
                      :route="descendant.href"
                      :index="descendant.data"
                      @click="handleSelect(descendant)"
                    >
                      {{ $t("i18n." + descendant.locale) }}
                    </el-menu-item>
                  </el-submenu>
                </template>
              </el-submenu>
            </template>
          </el-submenu>
        </el-menu>
        <div
          v-if="!isOuterFrom"
          class="icon-group"
          :class="isShowMenu ? 'active' : ''"
        >
        <!-- <Notify  class="right-menu-item" /> -->
          <el-button @click="changeLocale" class="lang">中/EN</el-button>
          <el-dropdown @command="enterPage">
            <span class="el-dropdown-link">
              {{ user.nick }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="/user/userInfo">
                  {{ $t("i18n.userInformation") }}
                </el-dropdown-item>
                <el-dropdown-item command="/user/changePassword">
                  {{ $t("i18n.changePassword") }}
                </el-dropdown-item>
                <el-dropdown-item command="/realTime/realTime">
                  {{ $t("i18n.myFavorite") }}
                </el-dropdown-item>
                <el-dropdown-item command="/user/updateLog">
                  {{ $t("i18n.updateLog") }}
                </el-dropdown-item>
                <el-dropdown-item
                  command="1"
                  v-if="$defined.OTHER.indexOf('同步数据') > -1"
                >
                  {{ $t("i18n.dataSynchronization") }}
                </el-dropdown-item>
                <el-dropdown-item divided command="2">
                  {{ $t("i18n.logout") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </header>
  </el-affix>
</template>
<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
// import Notify from "@/components/Notify/index.vue"

export default {
  name: "head",
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    // const GLOBAL = getCurrentInstance().appContext.config.globalProperties;
    const { proxy } = getCurrentInstance();

    const state = reactive({
      search: "",
      activeIndex: sessionStorage.getItem("activeIndex") || "1",
      menus: proxy.$defined.MENU,
      isShowMenu: false,
      screenWidth:
        document.documentElement.clientWidth || document.body.clientWidth,
      user: "",
      queryArr: [],
      isOuterFrom:
        localStorage.getItem("token") ==
        "USER82849715-05c2-448c-bb3d-1d5c93b8b624",
    });

    onMounted(() => {
      init();
      getUser();
    });

    const init = () => {
      window.addEventListener(
        "resize",
        () =>
          (state.screenWidth =
            document.documentElement.clientWidth || document.body.clientWidth)
      );
    };

    const getUser = async () => {
      var userId = localStorage.getItem("userId");
      const res = await proxy.$api.common.getUser(userId);
      state.user = res.data;
      var arr = state.user.roleDTOList[0].permissionList.filter(
        (item) => item.category == "其他"
      );
      var str = [];
      arr.map((item) => {
        str.push(item.name);
      });
      localStorage.setItem("other", window.encodeURIComponent(str));
      proxy.$defined.setOther(str);
    };

    const showMenu = () => {
      if (state.screenWidth <= 1024) {
        state.isShowMenu = state.isShowMenu ? false : true;
      }
    };

    const handleSelect = (item) => {
      sessionStorage.setItem("activeIndex", item.data);
      if (item.href.indexOf("http") == -1) {
        if (item.isBlank == "true") {
          let routeData = router.resolve({
            path: item.href,
          });
          window.open(routeData.href, "_blank");
        } else {
          router.push({
            path: item.href,
          });
        }
      } else {
        item.href = "http" + item.href.split("http")[1];
        window.open(item.href, "_blank");
      }
      showMenu();
      route.meta.isMenu = true;
    };

    const enterPage = (path) => {
      if (path == 1) {
        proxy.$api.common.renewalDataApi(); // 同步数据
      } else if (path == 2) {
        logout();
      } else {
        let routeData = router.resolve({
          path: path,
          query: { flag: 1 },
        });
        window.open(routeData.href, "_blank");
        sessionStorage.removeItem("activeIndex");
      }
    };

    const enterIndex = () => {
      router.push({
        path: "/",
        query: { from: localStorage.getItem("from") },
      });
    };

    const changeLocale = () => {
      let callback = () => {
        let locale = proxy.$i18n.locale;
        proxy.$i18n.locale = locale === "zh" ? "en" : "zh";
        localStorage.setItem("PLAY_LANG", proxy.$i18n.locale);
      };
      proxy.$defined.confirm(callback, t("i18n.toggle"), t("i18n.tips"));
    };

    const logout = async () => {
      const res = await proxy.$api.common.logoutApi({
        userId: localStorage.getItem("userId"),
      });
      if (res.success) {
        router.replace({
          path: "/login",
          query: {
            flag: 0,
          },
        });
        localStorage.clear();
        state.$store.commit("logout", "");
        emit("public_header", false);
      }
    };

    return {
      ...toRefs(state),
      init,
      getUser,
      showMenu,
      handleSelect,
      enterPage,
      enterIndex,
      changeLocale,
      logout,
    };
  },
};
</script>

<style lang="scss">
.el-affix {
  margin-bottom: 40px;
}
header {
  width: 100%;
  height: 60px;
  z-index: 999;
  background: url("../assets/indexImg/daohangbj.png") no-repeat;
  // background-size: cover;
  .maxContent {
    max-width: $max_width;
    color: $white;
    display: flex;
    align-items: center;
    margin: 0 auto;
    .div-logo {
      .img-logo {
        /* max-width: $width + 49; */
        max-height: 50px;
        object-fit: contain;
        margin-right: 40px;
      }
    }
    .el-menu {
      flex-grow: 1;
      background: transparent;
      border: none;
      .el-submenu__title,
      i {
        font-size: $font + 2;
        color: #fff !important;
        &:hover {
          background: transparent;
        }
      }
      i {
        font-size: $font;
      }
      .el-submenu.is-active {
        background: hsla(0, 0%, 84.3%, 0.33);
        .el-submenu__title {
          border: none;
        }
      }
    }
    .icon-group {
      display: inline-block;
      width: 170px;
      text-align: right;
      > * {
        padding: 0 10px;
        vertical-align: middle;
        min-height: 26px;
        height: 26px;
        line-height: 26px;
        color: $white;
      }
      .el-dropdown {
        border: 1px solid #dcdfe6;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .el-button {
        background: transparent;
        margin-right: 12px;
      }
    }
  }
}
@media screen and (min-width: 1921px) {
  header {
    background-size: cover;
  }
}
@media screen and (max-width: 1024px) {
  .el-affix {
    margin-bottom: 30px;
  }
  header {
    .maxContent {
      padding: 5px 10px;
      .el-menu {
        background: #fff;
        position: absolute;
        z-index: 1000;
        top: 65px;
        display: none;
        box-shadow: 0 2px 12px 0 #ccc;
        .el-submenu__title,
        i {
          color: #333 !important;
        }
        .el-submenu {
          float: none;
        }
      }
      .el-menu.active {
        display: block;
      }
      .lines {
        position: absolute;
        right: 10px;
        border: 1px solid #fff;
        border-radius: 3px;
        padding: 3px;
        span {
          width: 15px;
          height: 1px;
          background: #fff;
          margin: 4px 0;
          display: block;
        }
      }
      .icon-group {
        position: absolute;
        right: 45px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  header {
    .maxContent {
      padding: 5px 10px;
      .lang {
        display: none;
      }
      .lines {
        position: absolute;
        right: 10px;
        border: 1px solid #fff;
        border-radius: 3px;
        padding: 3px;
        span {
          width: 15px;
          height: 1px;
          background: #fff;
          margin: 4px 0;
          display: block;
        }
      }
      .icon-group {
        width: 125px;
      }
    }
  }
}
</style>
