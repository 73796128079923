export default [
  {
    path: "/realtime/realtime",
    name: "Realtime",
    component: () => import("../views/realtime/Realtime.vue"),
    // meta: { keepAlive: true },
  },
  {
    path: "/realtime/detail",
    name: "Detail",
    component: () => import("../views/realtime/Detail.vue"),
  },
  {
    path: "/realtime/collector",
    name: "Collector",
    component: () => import("../views/realtime/Collector.vue"),
  },
  {
    path: "/realtime/maps",
    name: "MapView",
    component: () => import("../views/realtime/MapView.vue"),
  },
  {
    path: "/realtime/all",
    name: "All",
    component: () => import("../views/realtime/All.vue"),
  },
  {
    path: "/realtime/windowElevator",
    name: "WindowElevator",
    component: () => import("../views/realtime/WindowElevator.vue"),
  },
  {
    path: "/realTime/eleHistoryFault",
    name: "EleHistoryFault",
    component: () => import("../views/realtime/EleHistoryFault.vue"),
  },
  {
    path: "/realTime/terminalState",
    name: "TerminalState",
    component: () => import("../views/realtime/TerminalState.vue"),
  },
  {
    path: "/realTime/liftParams",
    name: "LiftParams",
    component: () => import("../views/realtime/LiftParams.vue"),
  },
  {
    path: "/realTime/videoSurveillance",
    name: "VideoSurveillance",
    component: () => import("../views/realtime/VideoSurveillance.vue"),
  },
  {
    path: "/realTime/eleMapView",
    name: "EleMapView",
    component: () => import("../views/realtime/EleMapView.vue"),
  },
  {
    path: "/realTime/phoneVideo",
    name: "phoneVideo",
    component: () => import("../views/realtime/phoneVideo.vue"),
  },
  {
    path: "/realTime/phoneDetail",
    name: "phoneDetail",
    component: () => import("../views/realtime/phoneDetail.vue"),
  },
  {
    path: "/realTime/brand",
    name: "Brand",
    component: () => import("../views/realtime/Brand.vue"),
  },
  {
    path: "/realTime/allChart",
    name: "AllChart",
    component: () => import("../views/realtime/AllChart.vue"),
  },
  {
    path: "/realTime/dashboard",
    name: "Dashboard",
    component: () => import("../views/realtime/Dashboard.vue"),
  },
];
