/* 接口域名的管理 */
const url = process.env.VUE_APP_URL + ":8443";
// const url = process.env.VUE_APP_URL + ":30224";
const base = {
  sq: url + "/joycloud-user/api/",
  dl: url + "/joycloud-basic/api/",
  sdl: url + "/joycloud-basic/",
  bl: url + "/joycloud-workbill/api/",
  sw: url + "/joycloud-message/",
  ja: url + "/joycloud-assmbly/api/",
  js: url + "/joycloud-statistic/",
  jl: url + "/joycloud-lineage/api/",
  ad: url + "/joylive-ad/ad/",
  iot: url + "/joycloud-iot/api/",
  acc: url + "/joycloud-accessory/api/",
};
export default base;
