/* api接口的统一出口 */
// 公共模块接口
import common from "@/utils/api/common";
// 远程监控
import monitor from "@/utils/api/monitor";
// 网格救援
import rescue from "@/utils/api/rescue";
// 在线维保
import maintenance from "@/utils/api/maintenance";
// 系统设定
import system from "@/utils/api/system";
// 监察动态"
import supervision from "@/utils/api/supervision";
// 监察动态"
import advert from "@/utils/api/advert";
// 动态检测
import detection from "@/utils/api/detection";
// 其他模块的接口……

// 导出接口
export default {
  common,
  monitor,
  rescue,
  maintenance,
  system,
  supervision,
  advert,
  detection,
};
