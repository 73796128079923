/* monitor模块接口列表 */
import base from "./base";
// 导入http中创建的axios实例
import axios from "@/utils/http";
// 根据需求是否导入qs模块
import qs from "qs";
const monitor = {
  getArea(params) {
    return axios.post(base.dl + "mapCluster/area/gps", params);
  },
  getMan(params) {
    return axios.post(base.dl + "mapCluster/man/gps", params);
  },
  getElevatorByArea(params) {
    return axios.post(base.dl + "mapCluster/elevator/gps", params);
  },
  elePages(params) {
    // 在线监控页面，获取电梯信息
    return axios.get(base.dl + "elevator/main_form", {
      params: params,
    });
  },
  getHealth(params) {
    return axios.get(base.dl + `elevator/status/health/report/${params}`);
  },
  updateEleStatus(type, params) {
    // 在线监控页面—更新在线或离线状态
    return axios.post(base.dl + "elevator/status?type=" + type, params);
  },
  brandsAll(params) {
    // 在线监控页面，获取电梯品牌
    return axios.get(base.dl + "elevator_brand/all", {
      params: params,
    });
  },
  eleCounts(params) {
    // 在线监控页面，获取不同状态电梯数量
    return axios.get(base.dl + "elevator/counts", { params: params });
  },
  eleFavorite(id) {
    // 在线监控页面，更新收藏状态
    return axios.post(base.dl + "elevatorUsers/favorite/" + id);
  },
  eleSid() {
    // 在线监控页面，获取汇川sid
    return axios.get(base.dl + "elevator/sid");
  },
  eleDetail(id) {
    // 电梯详情接口
    return axios.get(base.sq + "elevators/monitors/" + id);
  },
  eleNotError(params) {
    // 电梯分布页面，获取非故障电梯
    return axios.get(base.dl + "elevator/all/non/fault/page", {
      params: params,
    });
  },
  eleNotErrorById(id) {
    // 产品分布-获取非故障电梯-单个电梯
    return axios.get(base.dl + "elevator/all/non/fault/" + id);
  },
  eleAllError(type) {
    // 电梯分布页面:加载处理中或未处理的故障电梯，处理中20，未处理10 地图
    return axios.get(base.dl + "elevator/all/fault/" + type);
  },
  eleAllPages(type, params) {
    // 电梯分布页面:加载处理中或未处理的故障电梯，处理中20，未处理10
    if (type == 10) {
      return axios.get(base.dl + "elevator/notHandled/pages", {
        params: params,
      });
    } else {
      return axios.get(base.dl + "elevator/handled/pages", {
        params: params,
      });
    }
  },
  eleMonitors(params) {
    // 视频监控 3G
    return axios.get(base.dl + "elevator/monitors", {
      params: params,
    });
  },
  eleBars() {
    // 电梯分布柱状图
    return axios.get(base.dl + "elevator/count/province");
  },
  eleBarsProvince(params) {
    // 电梯分布柱状图 点击省份
    return axios.get(base.dl + "elevator/count/province", {
      params: params,
    });
  },
  collectorsPages(params) {
    // 采集器页面 搜索（分页）
    return axios.post(base.dl + "collector/pages", params);
  },
  appFileUpload(params) {
    //上传签名
    return axios.post("https://iot.joylive.com:8443/joycloud-service-oss/api/upload/company", params);
  },
  processBill(params,task_id) {
    //提交报告
    return axios.put(base.bl + `flow/mobile/processBill/${task_id}`, params);
  },
  addCollector(params) {
    // 新增采集器
    return axios.post(base.dl + "collector/new", params);
  },
  editCollector(id, params) {
    // 编辑采集器
    return axios.put(base.dl + "collector/" + id, params);
  },
  deleteCollector(id) {
    // 删除采集器
    return axios.delete(base.dl + "collector/" + id);
  },
  brandPages(params) {
    // 电梯品牌页面 搜索（分页）
    return axios.get(base.dl + "elevator_brand/with_page", {
      params: params,
    });
  },
  addBrandOne(params) {
    // 新增电梯品牌
    return axios.post(base.dl + "elevator_brand/new", params);
  },
  editBrandOne(params) {
    // 编辑电梯品牌
    return axios.put(base.dl + "elevator_brand/update", params);
  },
  deleteBrand(id) {
    // 删除电梯品牌
    return axios.delete(base.dl + "elevator_brand/" + id);
  },
  exportBrand(params) {
    // 导出电梯品牌
    return axios.post(base.dl + "elevator_brand/download", params, {
      responseType: "blob",
    });
  },
  getHistoryFault(number, params) {
    // 电梯故障数据库
    return axios.get(base.dl + "elevatorFault/history/" + number, {
      params: params,
    });
  },
  SendPacket(params) {
    // 汇川长连接
    return axios.post(
      "http://bst.molian.tech:20000/api/services/app/SendPacket/Subscription",
      qs.stringify(params)
    );
  },
  getAllChartData() {
    // 大屏数据多图表
    return axios.get(base.bl + "dashboard");
  },
  getChecksumBystep() {
    // 新时达电梯websocket获取
    return axios.post(base.iot + "step/checksum");
  },
};

export default monitor;
