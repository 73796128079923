import { createRouter, createWebHashHistory } from "vue-router";
import RealtimeRouter from "./realtime";
import UserRouter from "./user";
import GridRouter from "./grid";
import maintenanceRouter from "./maintenance";
import installRouter from "./install";
import supervisionRouter from "./supervision";
import systemRouter from "./system";
import elevatorRouter from "./elevator";
import upkeepContractRouter from "./upkeepContract";
import detectionRouter from "./detection";
import advertRouter from "./advert";

const commonRoute = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/note",
    name: "Note",
    component: () => import("../views/Note.vue"),
  },
  {
    path: "/androidHelp",
    name: "AndroidHelp",
    component: () => import("../views/AndroidHelp.vue"),
  },
  {
    path: "/iosHelp",
    name: "IosHelp",
    component: () => import("../views/IosHelp.vue"),
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("../views/Report.vue"),
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("../views/Welcome.vue"),
  },
  {
    path: "/rescueMap",
    name: "RescueMap",
    component: () => import("../views/RescueMap.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const routes = [
  ...commonRoute,
  ...RealtimeRouter,
  ...UserRouter,
  ...GridRouter,
  ...maintenanceRouter,
  ...installRouter,
  ...supervisionRouter,
  ...systemRouter,
  ...elevatorRouter,
  ...upkeepContractRouter,
  ...detectionRouter,
  ...advertRouter,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: (to, from, position) => {
    if (position && to.meta.keepAlive) {
      return position;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from) => {
  to.meta.isNotKeepAlive =
    (to.meta.keepAlive && from.meta.keepAlive) || from.meta.isMenu;
});

export default router;
