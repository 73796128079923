export default [
  {
    path: "/advert/programList",
    name: "ProgramList",
    component: () => import("../views/advert/ProgramList.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/advert/createProgram",
    name: "CreateProgram",
    component: () => import("../views/advert/CreateProgram.vue"),
  },
  {
    path: "/advert/matterList",
    name: "MatterList",
    component: () => import("../views/advert/MatterList.vue"),
  },
  {
    path: "/device/list",
    name: "DeviceList",
    component: () => import("../views/advert/DeviceList.vue"),
  },
];
