export default [
  {
    path: "/detection/detectionBoard",
    name: "DetectionBoard",
    component: () => import("../views/detection/DetectionBoard.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/detection/detectioncou",
    name: "Detectioncou",
    component: () => import("../views/detection/Detectioncou.vue"),
  },
  {
    path: "/detection/partsManagement",
    name: "PartsManagement",
    component: () => import("../views/detection/PartsManagement.vue"),
  },
  {
    path: "/detection/partsMaintenance",
    name: "PartsMaintenance",
    component: () => import("../views/detection/PartsMaintenance.vue"),
  },
  {
    path: "/detection/queryOfProblemParts",
    name: "QueryOfProblemParts",
    component: () => import("../views/detection/QueryOfProblemParts.vue"),
  },
  {
    path: "/detection/batchElevatorParts",
    name: "BatchElevatorParts",
    component: () => import("../views/detection/BatchElevatorParts.vue"),
  },
  {
    path: "/detection/addBatchElevatorParts",
    name: "AddBatchElevatorParts",
    component: () => import("../views/detection/AddBatchElevatorParts.vue"),
  },
];
