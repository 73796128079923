export default [
  {
    path: "/elevator/elevator",
    name: "Elevator",
    component: () => import("../views/elevator/Elevator.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/elevator/addEditElevator",
    name: "AddEditElevator",
    component: () => import("../views/elevator/AddEditElevator.vue"),
  },
  {
    path: "/elevator/editBatch",
    name: "EditBatch",
    component: () => import("../views/elevator/EditBatch.vue"),
  },
  {
    path: "/elevator/floorCoU",
    name: "FloorCoU",
    component: () => import("../views/elevator/FloorCoU.vue"),
  },
  {
    path: "/elevator/faultCode",
    name: "FaultCode",
    component: () => import("../views/elevator/FaultCode.vue"),
  },
];
