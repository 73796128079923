import { ElMessageBox, ElMessage } from "element-plus";
//var baseURL =  process.env.NODE_FLAG == "production" ? "iot.joylive.com" : "eip.joylive.com";
  var baseURL = "iot.joylive.com";
export default {
  HEIGHT: document.documentElement.clientHeight || document.body.clientHeight,
  WIDTH: document.documentElement.clientWidth || document.body.clientWidth,
  imgUrl: "https://"+baseURL+":7443/files/juli/show?filePath=",
  uploadUrl: "https://" + baseURL + ":8443/joycloud-service-oss/api/upload/",
  advertUrl: "https://" + baseURL + ":8443/joylive-ad/ad/",
  imgFormalUrl: "https://"+baseURL+":7443/files/juli/show?filePath=",
  fileFormalUrl: "https://" + baseURL + ":50021/files/juli/show?filePath=",
  QUERY: window.decodeURIComponent(localStorage.getItem("query")),
  MENU: JSON.parse(window.decodeURIComponent(localStorage.getItem("menu"))),
  OTHER: window.decodeURIComponent(localStorage.getItem("other")),
  TOKEN: localStorage.getItem("token"),
  setToken(token) {
    this.TOKEN = token;
  },
  setHeight(height) {
    this.HEIGHT = height;
  },
  setQuery(query) {
    this.QUERY = query;
  },
  setMenu(menu) {
    this.MENU = menu;
  },
  setOther(other) {
    this.OTHER = other;
  },
  toLowerLine(value) {
    // 驼峰式转下横线
    var temp = value.replace(/[A-Z]/g, function (match) {
      return "_" + match.toLowerCase();
    });
    if (temp.slice(0, 1) === "_") {
      //如果首字母是大写，执行replace时会多一个_，这里需要去掉
      temp = temp.slice(1);
    }
    return temp;
  },
  exportFunc(res) {
    let fileName = decodeURI(res.headers["content-disposition"].split("=")[1]);
    let url = window.URL.createObjectURL(
      new Blob([res.data], { type: "application/vnd.ms-excel" })
    );
    // 将`blob`对象转化成一个可访问的`url`
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  tip(msg, type = "warning") {
    ElMessage({
      message: msg,
      type: type,
      showClose: true,
    });
  },
  confirm(callback, msg, title) {
    ElMessageBox.confirm(msg, title, {
      type: "warning",
    })
      .then(callback)
      .catch(() => {});
  },
  exportJsFunc(obj) {
    if (obj.params) {
      for (var k in obj.params) {
        obj.url = this.addURLParam(obj.url, k, obj.params[k]);
      }
    }
    var xhr = new XMLHttpRequest();
    xhr.open("GET", obj.url, true); // 也可以使用POST方式，根据接口
    xhr.responseType = "blob"; // 返回类型blob
    xhr.setRequestHeader("Authorization", this.TOKEN);
    xhr.onload = function () {
      // 请求完成
      let fileName = decodeURI(
        xhr.getResponseHeader("Content-disposition").split("=")[1]
      );
      if (this.status === 200) {
        // 返回200
        var blob = this.response;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = fileName;
          a.href = e.target.result;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
      }
    };
    xhr.send();
  },
  addURLParam(url, name, value) {
    url += url.indexOf("?") == -1 ? "?" : "&";
    url += encodeURIComponent(name) + "=" + encodeURIComponent(value);
    return url;
  },
  format(d, fmt = "yyyy-MM-dd") {
    if (d) {
      var date = new Date(d);
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    } else {
      return "";
    }
  },
  btnPermission(data) {
    let permissions = this.QUERY.concat(this.OTHER);
    let isHave = permissions.includes(data);
    return isHave;
  },
  getProvinceData() {
    var data = require("../json/city.json");
    var province = [],
      city = [],
      block = [];
    for (var item in data) {
      if (item.match(/0000$/)) {
        province.push({
          value: item,
          label: data[item],
          children: [],
        });
      } else if (item.match(/00$/)) {
        city.push({
          value: item,
          label: data[item],
          children: [],
        });
      } else {
        block.push({
          value: item,
          label: data[item],
        });
      }
    }
    // 分类市级
    for (var index in province) {
      for (var index1 in city) {
        if (
          province[index].value.slice(0, 2) === city[index1].value.slice(0, 2)
        ) {
          province[index].children.push(city[index1]);
        }
      }
    }
    // 分类区级
    for (var item1 in city) {
      for (var item2 in block) {
        if (block[item2].value.slice(0, 4) === city[item1].value.slice(0, 4)) {
          city[item1].children.push(block[item2]);
        }
      }
    }
    return province;
  },
  setNullValue(value) {
    let val = null;
    if (value instanceof Array) {
      val = [];
    } else if (value instanceof Object) {
      val = {};
    } else {
      val = "";
    }
    return val;
  },
  getArrayByParam(arr, k) {
    let keys = [];
    arr.map((item) => {
      keys.push(item[k]);
    });
    return keys;
  },
};
