export default [
  {
    path: "/system/companyMaintain",
    name: "CompanyMaintain",
    component: () => import("../views/system/CompanyMaintain.vue"),
  },
  {
    path: "/system/companyMaintaincou",
    name: "CompanyMaintaincou",
    component: () => import("../views/system/CompanyMaintaincou.vue"),
  },
  {
    path: "/system/baseviewAllot",
    name: "BaseviewAllot",
    component: () => import("../views/system/BaseviewAllot.vue"),
    // meta: { keepAlive: true },
  },
  {
    path: "/system/baseviewAllotUpdate",
    name: "BaseviewAllotUpdate",
    component: () => import("../views/system/BaseviewAllotUpdate.vue"),
  },
  {
    path: "/system/qualification",
    name: "Qualification",
    component: () => import("../views/system/Qualification.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/system/qualificationcou",
    name: "Qualificationcou",
    component: () => import("../views/system/Qualificationcou.vue"),
  },
  {
    path: "/system/remindyearCheck",
    name: "RemindyearCheck",
    component: () => import("../views/system/RemindyearCheck.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/system/remindupkeepContractRemind",
    name: "RemindupkeepContractRemind",
    component: () => import("../views/system/RemindupkeepContractRemind.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/system/remindmaintenancePlanBoard",
    name: "RemindmaintenancePlanBoard",
    component: () => import("../views/system/RemindmaintenancePlanBoard.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/system/photoEditor",
    name: "PhotoEditor",
    component: () => import("../views/system/PhotoEditor.vue"),
  },
  {
    path: "/system/quickMark",
    name: "QuickMark",
    component: () => import("../views/system/QuickMark.vue"),
  },
  {
    path: "/system/area",
    name: "Area",
    component: () => import("../views/system/Area.vue"),
  },
  {
    path: "/system/faultSetting",
    name: "FaultSetting",
    component: () => import("../views/system/FaultSetting.vue"),
  },
  {
    path: "/system/setFaultPage",
    name: "SetFaultPage",
    component: () => import("../views/system/SetFaultPage.vue"),
  },
  {
    path: "/system/custom",
    name: "Custom",
    component: () => import("../views/system/Custom.vue"),
  },
];
