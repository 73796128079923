export default [
  {
    path: "/maintenance/maintenancePlan",
    name: "MaintenancePlan",
    component: () => import("../views/maintenance/MaintenancePlan.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/maintenance/workBillBoard",
    name: "WorkBillBoard",
    component: () => import("../views/maintenance/WorkBillBoard.vue"),
  },
  {
    path: "/maintenance/workBillBoardInquiry",
    name: "WorkBillBoardInquiry",
    component: () => import("../views/maintenance/WorkBillBoardInquiry.vue"),
  },
  {
    path: "/maintenance/workBillwbStatus",
    name: "WorkBillwbStatus",
    component: () => import("../views/maintenance/WorkBillwbStatus.vue"),
  },
  {
    path: "/maintenance/generateWorkBillView",
    name: "generateWorkBillView",
    component: () => import("../views/maintenance/GenerateWorkBillView.vue"),
  },
  {
    path: "/chart/resultStatic",
    name: "ResultStatic",
    component: () => import("../views/chart/ResultStatic.vue"),
  },
  {
    path: "/chart/countYearBills",
    name: "CountYearBills",
    component: () => import("../views/chart/CountYearBills.vue"),
  },
  {
    path: "/chart/countMonthBills",
    name: "CountMonthBills",
    component: () => import("../views/chart/CountMonthBills.vue"),
  },
  {
    path: "/chart/faultStatic",
    name: "FaultStatic",
    component: () => import("../views/chart/FaultStatic.vue"),
  },
  {
    path: "/chart/billStatic",
    name: "BillStatic",
    component: () => import("../views/chart/BillStatic.vue"),
  },
  {
    path: "/chart/faultTypeChart",
    name: "FaultTypeChart",
    component: () => import("../views/chart/FaultTypeChart.vue"),
  },
  {
    path: "/chart/faultReasonChart",
    name: "FaultReasonChart",
    component: () => import("../views/chart/FaultReasonChart.vue"),
  },
  {
    path: "/chart/inspectStatic",
    name: "InspectStatic",
    component: () => import("../views/chart/InspectStatic.vue"),
  },
  {
    path: "/chart/inspectReasonChart",
    name: "InspectReasonChart",
    component: () => import("../views/chart/InspectReasonChart.vue"),
  },
  {
    path: "/chart/responseStatic",
    name: "ResponseStatic",
    component: () => import("../views/chart/ResponseStatic.vue"),
  },
  {
    path: "/chart/billChartStatic",
    name: "BillChartStatic",
    component: () => import("../views/chart/BillChartStatic.vue"),
  },
  {
    path: "/maintenance/annualInannualInspectionChart",
    name: "AnnualInannualInspectionChart",
    component: () =>
      import("../views/maintenance/AnnualInannualInspectionChart.vue"),
  },
  {
    path: "/maintenance/mpBoard",
    name: "MpBoard",
    component: () => import("../views/maintenance/MpBoard.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/maintenance/personnelHistoryRoute",
    name: "PersonnelHistoryRoute",
    component: () => import("../views/maintenance/PersonnelHistoryRoute.vue"),
  },
  {
    path: "/maintenance/personnelGPRS",
    name: "PersonnelGPRS",
    component: () => import("../views/maintenance/PersonnelGPRS.vue"),
  },
  {
    path: "/maintenance/resultBoard",
    name: "ResultBoard",
    component: () => import("../views/maintenance/ResultBoard.vue"),
  },
  {
    path: "/maintenance/inspectionBoard",
    name: "InspectionBoard",
    component: () => import("../views/maintenance/InspectionBoard.vue"),
  },
  {
    path: "/maintenance/reportMaintain",
    name: "ReportMaintain",
    component: () => import("../views/maintenance/ReportMaintain.vue"),
  },
  {
    path: "/maintenance/spDataReport",
    name: "SpDataReport",
    component: () => import("../views/maintenance/SpDataReport.vue"),
  },
  {
    path: "/maintenance/mPlanBath",
    name: "MPlanBath",
    component: () => import("../views/maintenance/MPlanBath.vue"),
  },
  {
    path: "/maintenance/maintenanceItemsConfigitemCompany",
    name: "MaintenanceItemsConfigitemCompany",
    component: () =>
      import("../views/maintenance/MaintenanceItemsConfigitemCompany.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/maintenance/maintenancePlanInspect",
    name: "MaintenancePlanInspect",
    component: () => import("../views/maintenance/MaintenancePlanInspect.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/maintenance/maintenanceItemsConfigselect",
    name: "MaintenanceItemsConfigselect",
    component: () =>
      import("../views/maintenance/MaintenanceItemsConfigselect.vue"),
  },
  {
    path: "/maintenance/maintenanceItemsConfigitemInfo",
    name: "MaintenanceItemsConfigitemInfo",
    component: () =>
      import("../views/maintenance/MaintenanceItemsConfigitemInfo.vue"),
  },
  {
    path: "/maintenance/annualInannualInspectionView",
    name: "AnnualInannualInspectionView",
    component: () =>
      import("../views/maintenance/AnnualInannualInspectionView.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/maintenance/annualInfocou",
    name: "AnnualInfocou",
    component: () => import("../views/maintenance/AnnualInfocou.vue"),
  },
  {
    path: "/maintenance/workBillStatistics",
    name: "WorkBillStatistics",
    component: () => import("../views/maintenance/WorkBillStatistics.vue"),
  },
];
