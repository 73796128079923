// const src = "https://iot.joylive.com:7443/files/juli/show?pdfPath=";
const src = "https://iot.joylive.com:7443/files/juli/show?filePath=";
export default {
  url: {
    // 维保计划导入模板
    importPlan: src + "planExcel/importPlan.xls&fileName=维保计划导入模板.xls",
    videoForThree:
      "https://hcdt.dataserver.cn/1.1/monitor/hangzhou/elevatorRealTimeCall.jsp?registerCode=",
    importElevator:
      src + "planExcel/elevatorModel.xlsx&fileName=电梯导入模板.xls",
    pjUpkeep: src + "planExcel/pjUpkeep.xls&fileName=配件合同导入模板.xls",
    xlUpkeep: src + "planExcel/xlUpkeep.xls&fileName=修理合同导入模板.xls",
    zxUpkeep: src + "planExcel/zxUpkeep.xls&fileName=技术支持导入模板.xls",
    wbUpkeep: src + "planExcel/wbUpkeep.xls&fileName=保养合同导入模板.xls",
    dashboard2: "https://iot.joylive.com/caster/view.html?id=43",
  },
  billStatus: [
    // 工单状态
    { id: "5", locale: "SEND", color: "LightSeaGreen", last: "0" }, // 待派发
    { id: "10", locale: "WAITING", color: "#5BA8F6", last: "1" }, //待接受
    { id: "15", locale: "ACCEPT", color: "MediumTurquoise", last: "0" }, //待执行
    { id: "20", locale: "INHAND", color: "CornflowerBlue", last: "0" }, //处理中
    { id: "30", locale: "SUSPEND", color: "Chocolate", last: "1" }, //已暂停
    { id: "40", locale: "REFUSE", color: "OrangeRed", last: "1" }, //已拒绝
    { id: "45", locale: "DONEREFUSE", color: "#A763EF", last: "1" }, //拒绝已处理
    { id: "50", locale: "ACCOMPLISH", color: "green", last: "0" }, //已完成
    { id: "51", locale: "PENDING", color: "#333", last: "0" }, //待审查
    { id: "52", locale: "PASSED", color: "#333", last: "0" }, //合格
    { id: "53", locale: "FAILED", color: "#333", last: "0" }, //不合格
    { id: "56", locale: "excellent", color: "#333", last: "0" }, //优秀
    { id: "57", locale: "good", color: "#333", last: "0" }, //良好
    { id: "55", locale: "TOFIX", color: "LightSeaGreen", last: "0" }, //转维修
    { id: "60", locale: "EVALUATION", color: "LimeGreen", last: "0" }, //待评价
  ],
  labelStyle: {
    position: "absolute",
    backgroundColor: "#fff",
    border: "none",
    padding: "1px",
    whiteSpace: "nowrap",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontStretch: "normal",
    fontSize: "12px",
    lineHeight: "normal",
    left: "20px",
    top: "-10px",
    maxWidth: "460px",
    boxShadow: "0px 2px 10px 0px rgba(30,72,111,0.58)",
  },
  labelStyle2: {
    position: "absolute",
    backgroundColor: "rgba(255,255,255,0)",
    border: "none",
    padding: "1px",
    whiteSpace: "break-spaces",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontStretch: "normal",
    fontSize: "12px",
    lineHeight: "normal",
    left: "12px",
    top: "16px",
    maxWidth: "40px",
    color: "#fff",
    alignItems:"center",
    textAlign: "center",
    width: "max-content"
    // boxShadow: "0px 2px 10px 0px rgba(30,72,111,0.58)",
  },
  month: [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ],
  personMapStyle: [
    {
      featureType: "subway",
      elementType: "all",
      stylers: {
        visibility: "off",
      },
    },
    {
      featureType: "railway",
      elementType: "all",
      stylers: {
        visibility: "off",
      },
    },
    {
      featureType: "land",
      elementType: "all",
      stylers: {
        visibility: "on",
      },
    },
    {
      featureType: "green",
      elementType: "all",
      stylers: {
        visibility: "off",
      },
    },
    {
      featureType: "highway",
      elementType: "all",
      stylers: {
        visibility: "off",
      },
    },
  ],
  search: [
    // 搜索条件
    { locale: "elevatorNo", value: "number" },
    { locale: "equipmentNo", value: "equipment_number" },
    { locale: "address", value: "address" },
    { locale: "projectName", value: "project_name" },
    { locale: "nineNo", value: "jury_number" },
    { locale: "intelligenceCode", value: "intel_hardware_number" },
  ],
};
